<template>
  <div>
    <el-dialog title="视频"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                style="width: 100%">
        <!-- <el-table-column prop="check_point_desc"
                         align="left"
                         label="考点" /> -->
        <el-table-column prop="filename"
                         align="left"
                         label="视频名称" />
        <!-- <el-table-column prop="subject_name"
                         width="150"
                         align="left"
                         label="科目" /> -->
        <el-table-column width="200"
                         label="操作">
          <template slot-scope="{row}">
            <div class="btn"
                 @click="doPaper(row)">
              播放视频
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import { getSonKnowledge } from '@/api/knowledge.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    doPaper (row) {
      this.$router.push("/video?video=" + row.url)
      // window.open(row.video_link, '_blank')
    },
    async initList (params) {
      let { data } = await getSonKnowledge(params)
      this.tableData = data.list
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog {
  border-radius: 20rem;
  // height: 600rem;  
  margin-top: 198rem !important;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  text-indent: 60rem;

  font-size: 20rem;
}

.checkpoint {
  font-weight: bold;
  color: #333333;
  font-size: 20rem;
}
.btn {
  width: 140rem;
  height: 44rem;
  background: #2196f3;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-weight: bold;
  color: #ffffff;
  text-indent: 0;
  cursor: pointer;
}
</style>